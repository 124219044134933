import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {Context as MemoryContext} from '../context/MemoryContext';
import GameTile from "./widgets/GameTile";

const GamesList = () => {
    const {state:{games}} = useContext(MemoryContext);
    const navigate = useNavigate();

    return(
        <div className="game-grid-container">
            <h1 className="game-text">Select a game to play</h1>
            <div className="game-tiles">
                {
                    games.map(game => (
                        <div key={game.id} className="game-tile" onClick={() => navigate(`/game/${game.id}`)} >
                            <GameTile game={game}  />
                        </div>
                    ))
                }
            </div>
        </div>

    );
}

export default GamesList;