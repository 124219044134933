import { useState, useContext, useEffect } from "react";
import { matchPath } from "react-router-dom";
import {Context as TimerContext} from '../../context/TimerContext';

const Timer = (props) => {
    const {setTimer,state:{time}} = useContext(TimerContext);
    let timer;

    useEffect(() => {
        if(time===0) {
            props.setGameOver(true);
            props.startTimer(false);
        }
        
    },[time===0]);
    
    if(props.timerOn) {
        if(time !== 0) {
            timer = setTimeout(() => {
                setTimer(time-1);
            },1000);
        }
    }


    return(

        <div className="timer-container">
            {
                time > -1
                ? <div className="timer-grid">
                    <div>{Math.floor((time / 60) / 10)}</div>
                    <div>{Math.floor((time / 60) %10)}</div>
                    <p>:</p>
                    <div>{Math.floor((time % 60) /10)}</div>
                    <div>{time % 10}</div>
                </div>
                : <div></div>
            }
        </div>
    
    );
}


export default Timer;