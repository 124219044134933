import { useEffect, useState } from "react";
import moment from "moment";

const GameTile = (props) => {
    const [randomPicture,setRandomPicture] = useState({});

    useEffect(() => {
        const randIndex = Math.floor(Math.random() * props.game.pictures.length);
        setRandomPicture(props.game.pictures[randIndex]);
    },[]); 

    const convertDate = (isoDate) => {
        return moment(isoDate).format('DD/MM/YYYY');
    }

    return(
        <>
            <div className="tile-head">
                <h1>{props.game.name}</h1>
            </div>
            <div className="tile-image-container">
                <img src={randomPicture.picture} alt={randomPicture.name}  className="tile-image"/>
            </div>
            <div className="tile-footer">
                <p>Created by {props.game.creator.name}</p>
                <p>On {convertDate(props.game.date_added)}</p>
            </div>
        </>
    );
}

export default GameTile;