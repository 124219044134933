import { useContext, useEffect, useState } from "react";
import {Context as MemoryContext} from '../../context/MemoryContext';
import Modal from "../dialogs/Modal";
import CardTile from "./CardTile";

const CardTileGrid = (props) => {

    const {setMoveCount,state:{selectedGame,moveCount}} = useContext(MemoryContext);
    const [gridSize, setGridSize] = useState(0);
    const [cards, setCards] = useState([]);
    const [revealList,setRevealList] = useState([]);
    const [matching,setMatching] = useState(false);
    const [showGameNotStartedMessage, setShowGameNotStartedMessage] = useState(false);

    const allowedRowLengths = [2,4,6,8];

    //Get the grid size to use based on the length of the pictures
    function getGrid(length,factor=null) {
        !factor ? factor = length - 1 : factor = factor;
        if(factor > 1) {
            const lengthDivided = length % factor
            if(lengthDivided === 0) {
                const foundRowLength = allowedRowLengths.find(e => e === factor);
                if(foundRowLength) {
                    return foundRowLength;
                } else {
                    return getGrid(length,factor-1);
                }
            } else {
                return getGrid(length, factor-1);
            }
        } else {
            return 0;
        }
    }


    function shuffleArray(array) {
        let curId = array.length;
        // There remain elements to shuffle
        while (0 !== curId) {
          // Pick a remaining element
          let randId = Math.floor(Math.random() * curId);
          curId -= 1;
          // Swap it with the current element.
          let tmp = array[curId];
          array[curId] = array[randId];
          array[randId] = tmp;
        }
        return array;
    }

    const setupGame = async () => {
        const pictureList = [...selectedGame.pictures, ...selectedGame.pictures];
        const pictureListRandomised = shuffleArray(pictureList);
        setGridSize(getGrid(pictureListRandomised.length));
        setCards(pictureListRandomised);
    }

    const lastTwoMatch = (revealIndexList) => {
        if(revealIndexList.length > 1) {
            const last = cards[revealIndexList[revealIndexList.length-1]];
            const lastButOne = cards[revealIndexList[revealIndexList.length-2]];
            if(last.id === lastButOne.id) {
                return true;
            }
        }
        return false;
    }

    const revealTile = (e) => {
        //Exit if the game has not been started yet
        if(!props.gameStarted) {
            setShowGameNotStartedMessage(true);
            return;
        }
        //Get the index of the card selected
        const idx = e.currentTarget.querySelector('.cardback').id;
        //Check that they are not clicking on the same one twice
        if(revealList.includes(parseInt(idx))) {
            return;
        }

        if(!matching) {
            let newRevealList = [...revealList,parseInt(idx)];

            //Two tiles have been flipped
            if(newRevealList.length % 2 == 0) {
                setMoveCount(moveCount + 1);

                setTimeout(() => {
                    if(!lastTwoMatch(newRevealList)) {
                        newRevealList.pop();
                        newRevealList.pop();
                        setRevealList(newRevealList);
                        setMatching(false);
                        return;
                    } else {
                        //End of game condition
                        if(newRevealList.length === cards.length) {
                            props.endGame();
                        } else {
                            setMatching(false);
                        }
                        return;
                    }
                },2000);
                setMatching(true);
                setRevealList(newRevealList);
            } else {
                setRevealList(newRevealList);
            }
        }

    }

    useEffect(() => {
        setupGame();
    },[]);
    

    return(
        <div className={`card-grid-${gridSize}`}>
            {
                showGameNotStartedMessage
                ?<Modal 
                    message="Game has Not Started Yet" 
                    title = "Game not Started"
                    closeAction={setShowGameNotStartedMessage}
                />
                : <></>
            }
            {
                cards.map((picture,i) => (
                    <div key={`${picture.id}${i}`} onClick={(e) => revealTile(e)}>
                        <CardTile
                            index = {i}
                            picture={picture} 
                            reveal={revealList.includes(i) ? true : false}
                        />
                    </div>
                ))
            }
        </div>
    );

}

export default CardTileGrid;