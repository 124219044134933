import cardBack from '../../assets/brain_card_back.jpg';

const CardTile = (props) => {
    return(
        <div id={props.index} className="cardback">
            {
                props.reveal
                ?<img src={props.picture.picture} alt="card tile" />
                :<img src={cardBack} alt="card tile" />
            }
        </div>
    );
}

export default CardTile;