import { useParams, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {Context as MemoryContext} from '../context/MemoryContext';
import {Context as TimerContext} from '../context/TimerContext';
import Timer from './widgets/Timer';
import CardTileGrid from "./widgets/CardTileGrid";
import Modal from "./dialogs/Modal";



const Game = () => {
    const {setGame,setMoveCount,state:{games,selectedGame,moveCount}} = useContext(MemoryContext);
    const {setTimer} = useContext(TimerContext);
    const [timerOn,startTimer] = useState(false);
    const [gameOver,setGameOver] = useState(false);
    const [gameComplete,setGameComplete] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    const endGame = () => {
        startTimer(false);
        setGameComplete(true);
    }


    const gameOverAction = () => {
        setTimer(-1);
        setMoveCount(0);
        setGame(null);
        navigate('/');
    }
 
    const setupGame = async () => {
        const game = games.filter(e => params.id == e.id)[0];
        await setGame(game);
    }


    useEffect(() => {
        setupGame();
    },[]);
    
    return(
        <div>
            {
                gameOver
                ? <Modal  
                    message="Out of Time!"
                    closeAction={gameOverAction}
                    okButtonName="Back to Games"
                />
                : <></>
            }
            {
                gameComplete
                ? <Modal  
                    message={`Congratulations you Have Won! In ${moveCount} moves.`}
                    closeAction={gameOverAction}
                    okButtonName="Back to Games"
                />
                : <></>
            }
            <Timer timerOn={timerOn} setGameOver={setGameOver} startTimer={startTimer} />
            <h1 className="game-text">This is the game {params.id}</h1>
            <h2 className="game-text">Moves: {moveCount}</h2>
            <button onClick={() => {
                setGameOver(false); 
                startTimer(true);
                setTimer(60); 
            }} className={timerOn? 'hidden' : 'btn'} >Start Game</button>
            { selectedGame ? <CardTileGrid gameStarted={timerOn} endGame={endGame} /> : <></> }

        </div>
    );
}
export default Game;