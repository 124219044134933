

const OkButtonSet = (props) => {
    return(
        <div className="buttonSet">
            <button onClick={() => props.okAction()} className="btn" >{props.okButtonName ? props.okButtonName : 'Ok'}</button>
        </div>
    );
}

export default OkButtonSet;