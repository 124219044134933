import brainOrange from '../assets/brain-orange.png';

const Header = () => {
    return(
        <div className='header-content'>
            <div className="brain-logo">
                <img src={brainOrange} />
            </div>
            <h1>Memory Game</h1>
            <div className="brain-logo">
                <img src={brainOrange} />
            </div>
        </div>
    );
}

export default Header;