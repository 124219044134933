import './App.css';
import { Routes, Route} from "react-router-dom";
import { useEffect, useContext, useState } from 'react';
import {getGames} from './network/apiactions';
import {Context as MemoryContext} from './context/MemoryContext'; 
import GamesList from './components/GamesList';
import Game from './components/Game';
import Layout from './components/Layout';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  const {setGames} = useContext(MemoryContext);
  const [loaded,setLoaded] = useState(false);

  const loadGames = async () => {
    const games = await getGames();
    setGames(games.data);
    setLoaded(true);
  }

  useEffect(() => {
    loadGames();
  },[]);

  return (
    <div className="App">
      <header className="app-header">
        <Header />
      </header>
      <main>
        { loaded 
          ? <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<GamesList />} />
                  <Route path="/game/:id" element={<Game />} />
              </Route>
          </Routes>
          : <p>Loading...</p> }
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
