import createDataContext from './createDataContext';

const defaultState = {
  games: [],
  selectedGame : null,
  moveCount : 0,
//   time : 0,
//   timerFinished : false,
};

const gamesReducer = (state,action) => {
  switch(action.type) {
    //Setters
    case 'setGames':
        return {...state,games:action.payload};
    case 'setGame':
      return {...state,selectedGame:action.payload};
    case 'setMoveCount':
      return {...state,moveCount:action.payload};
    default:
      return defaultState;
  }
};

//Setters

const setGames = (dispatch) => (games) => {
  dispatch({type:'setGames', payload:games});
}

const setMoveCount = (dispatch) => (amount) => {
  dispatch({type:'setMoveCount', payload:amount});
}

// const setTimer = (dispatch) => (time) => {
//     dispatch({type:'setTimer', payload:time});
// }

// const setTimerFinished = (dispatch) => (finished) => {
//     dispatch({type:'setTimer', payload:finished});
// }


//Getters

//Sets the state
// const setGame = (dispatch) => (id) => {
//     dispatch({type:'getGame', payload:id});
//   }

const setGame = (dispatch) => (game) => {
  dispatch({type:'setGame', payload:game});
}

//Returns the selected game
// const getGame = (dispatch) => (id) => {
//   setGame()
//   const game = state.games.filter(e => e.id == action.payload);
//   return game;
// }



export const {Provider, Context} = createDataContext (
    gamesReducer,
    { setGames ,setGame, setMoveCount},
    {...defaultState}
);