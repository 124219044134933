import createDataContext from './createDataContext';

const defaultState = {
  time : -1,
  timerFinished : false,
};

const timerReducer = (state,action) => {
  switch(action.type) {
    //Setters
    case 'setTimer':
        return {...state,time:action.payload};
    case 'setTimerFinished':
        return {...state,timerFinished:action.payload};
    //Getters
    default:
      return defaultState;
  }
};

//Setters

const setTimer = (dispatch) => (time) => {
    dispatch({type:'setTimer', payload:time});
}

const setTimerFinished = (dispatch) => (finished) => {
    dispatch({type:'setTimer', payload:finished});
}


//Getters




export const {Provider, Context} = createDataContext (
    timerReducer,
    { setTimer,setTimerFinished, },
    {...defaultState}
);