import {conn} from './api';

const getGames = () => {
    return new Promise(async (resolve,reject) => {
        await conn.get('/games').then((res) => {
            return resolve(res);
        }).catch((err) => {
            return resolve(err.data);
        });
    });
}

export {getGames};