import OkButtonSet from './OkButtonSet';

const Modal = (props) => {

    return(
        <>
            <div className="backdrop" onClick={() => props.closeAction()} ></div>
        
            <div className="modal">
                <div className="header">
                    <h1>{props.title}</h1>
                </div>
                <div className="body">
                    <p>{props.message}</p>
                </div>
                <div className="footer">
                    <OkButtonSet okAction={props.closeAction} okButtonName={props.okButtonName} />
                </div>
            </div>
        </>

    )
}

export default Modal;